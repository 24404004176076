<template>
  <div>
    <form-builder ref="formBuilder" :schema="schema"/>

    <div class="q-py-md q-mt-md q-px-xs border-top">
      <products-search
          :products="products"
          :options="options"
          :is-expandable="false"
          :disabled="disabledDelete"
          :placeholder="$t('Search products in the order form')"
          no-padding
          @on-search="handleSearch"
          @submit="handleSubmit"
      >
        <q-btn
            :class="isMobile ? 'col-12 q-mt-md' : ''"
            color="dark"
            text-color="white"
            class="q-ml-sm"
            no-caps
            :label="$t('Create by SKU')"
            @save="handleSaveSKU"
            @click="handleImportByText"
        />


        <q-btn-dropdown
            v-if="order && order.type === 'return'"
            :class="isMobile ? 'col-12 q-mt-md' : ''"
            color="dark"
            text-color="white"
            class="q-ml-sm"
            no-caps
            :label="$t('Create new product')"
            :disable="disabled"
        >
          <q-list>
            <q-item clickable v-close-popup @click="create('simple')">
              <q-item-section>
                <q-item-label>
                  {{ $t('Simple type') }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="create('grouped')">
              <q-item-section>
                <q-item-label>
                  {{ $t('Grouped') }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="create('bundle')">
              <q-item-section>
                <q-item-label>
                  {{ $t('Bundle') }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </products-search>


      <q-table
          class="sticky-header-table q-mt-md"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :filter="filter"
          :columns="columns"
          :shop="shop"
          v-model:pagination="pagination"
          :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
          :loading="isLoading"
          virtual-scroll
          binary-state-sort
          @request="onRequest"
      >
        <template v-slot:body="props">
          <order-product-row-new
              v-if="props.row.count > 0"
              :data="props"
              :bundleProducts="bundleProducts"
              :order="order"
              :is-amazon="isAmazon"
              :products="items"
              :reserves="reserves"
              :disabled="disabled"
              :disabledDelete="disabledDelete"
              :instructions-service="instructionsService"
              :options="{
              warehouses: reservesWarehouses,
              reserve: getReserve(props.row),
              warehouse: warehouse,
              minPrice: 0,
              minAmount: order && order.type === 'return' ? 0 : 1,
              noColor: !!order && (!order.id || order.type === 'return'),
            }"
              @open="handleShowInfo"
              @open-reserve="handleReserve"
              @change="handleNumberUpdate"
              @duplicate="handleDuplicate"
              @delete="deleteRow"
          />
        </template>
      </q-table>

      <div class="row justify-end q-pt-md">
        <div class="border-top text-right" style="width: 250px">
          <div class="row q-py-sm text-subtitle1">
            <div class="col-6">
              <strong>{{ $t('Total') + ': ' }}</strong>
            </div>

            <div class="col-6">
              <strong>{{ totalPrice }}</strong>
            </div>
          </div>
        </div>
      </div>
      <import-products-text-area-modal ref="importProducts" @submit="handleImportByTextSubmit"/>

      <offer-modal ref="offerModal" @submit="handleOfferSubmit"/>

      <items-info-modal ref="itemsInfo"/>

      <barcode-input-modal ref="barcodeInputModal"/>

      <confirm-modal ref="confirmModal"/>

      <entity-update-modal ref="entityUpdateModal" :manual="fastMode"/>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import OfferModal from '../modals/OfferModal.vue'
import ProductsSearch from './ProductsSearch.vue'
import OrderProductRowNew from './OrderProductRowNew.vue'
import ItemsInfoModal from '../modals/ItemsInfoModal.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'
import _ from 'lodash'
import BarcodeInputModal from '@/apps/app/components/barcode-input-modal/BarcodeInputModal.vue'
import EntityUpdateModal from '@/apps/app/components/modals/EntityUpdateModal.vue'
import ConfirmModal from '@/apps/app/components/confirm-modal/ConfirmModal.vue'
import { InstructionsService } from '@/apps/app/services/instructions.service'
import ImportProductsTextAreaModal from '@/apps/app/components/modals/ImportProductsTextAreaModal.vue'

export default {
  name: 'OrderContent',
  emits: ['shop-change', 'warehouse-change', 'products-change', 'source-change'],
  components: {
    ImportProductsTextAreaModal,
    ConfirmModal, EntityUpdateModal, BarcodeInputModal,
    OfferModal,
    ProductsSearch,
    OrderProductRowNew,
    ItemsInfoModal
  },
  props: {
    isOrderLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledDelete: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default () {
        return null
      }
    },
    orderType: {
      type: String,
      default () {
        return null
      }
    },
    warehouse: {
      type: Object,
      default () {
        return null
      }
    },
    reserves: {
      type: Array,
      default () {
        return []
      }
    },
    reservesWarehouses: {
      type: Array,
      default () {
        return []
      }
    },
    shop: {
      type: Object,
      default () {
        return null
      }
    },
  },
  data () {
    return {
      filters: [],
      activatedFields: [
        'id',
        'price',
        'created.from',
        'created.to'
      ],
      isOpenFilter: false,
      orderProducts: [],
      isLoading: false,
      changes: {},
      filter: '',
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      search: '',
      paymentsStates: [
        { id: 'paid', name: this.$t('Paid') },
        { id: 'not_paid', name: this.$t('Not paid') }
      ],
      isDisabledWarehouse: false,
      isDisabledShop: false,
      fullShop: {},
      bundleProducts: [],
      warehouseOwner: null,
      instructionsService: null
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
    ]),
    columns () {
      return [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Reserves'),
          name: 'reserves',
          align: 'center',
          headerClasses: this.order && this.order.type === 'return'
              ? 'd-none'
              : '',
          classes: this.order && this.order.type === 'return'
              ? 'd-none'
              : '',
        },
        {
          label: this.$t('Total'),
          name: 'total',
          align: 'left'
        },
        this.isAmazon ?
            {
              label: this.$t('Amazon'),
              name: 'amazon',
              align: 'left'
            } : {},
        {
          name: 'after',
          align: 'right'
        }
      ]
    },
    isMobile () {
      return window.innerWidth < 700
    },
    items () {
      if (!this.order || !this.order.id && this.search) {
        return this.orderProducts.filter(x => {
          return JSON.stringify(x).toLowerCase().includes(this.search.toLowerCase())
        })
      }

      return this.orderProducts
    },
    products () {
      return this.orderProducts.map(x => {
        return {
          ...x._embedded.productOffer,
          ...x,
          id: x._embedded.productOffer.id,
          raw: x
        }
      })
    },
    options () {
      return {
        onlyOneShop: true,
        allWarehouses: true,
        disabledWarehouse: this.order && this.order.id,
        filter: this.order && this.order.type === 'bundle'
            ? [{ type: 'eq', field: 'type', value: 'bundle' }]
            : [],
        defaultValues: {
          shop: this.order && this.order._embedded.shop,
          warehouse: this.warehouse
        }
      }
    },
    totalPrice () {
      return this.orderProducts.reduce((acc, val) => {
        return acc + val.price * val.count
      }, 0).toFixed(2)
    },
    schema () {
      return {
        isLoading: this.isOrderLoading,
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                ref: 'shop',
                type: 'multiselect',
                label: this.$t('Store'),
                field: 'shop',
                value: this.order && this.order._embedded.shop,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                required: true,
                disabled: this.isDisabledShop || this.disabled,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active', 'blocked'] },
                      { type: 'in', field: 'type', values: ['shop', 'virtual'] }
                    ]
                  }

                  // if (
                  //     this.warehouse &&
                  //     this.warehouse._embedded &&
                  //     this.warehouse._embedded.owner
                  // ) {
                  //   query.filter.push({ type: 'eq', field: 'owner', value: this.warehouse._embedded.owner.id })
                  // }

                  return this.$service.shop.getAll(query)
                },
                onChange: (shop) => {
                  if (shop) {
                    this.loadWarehouse(shop).then((shop) => {
                      if (shop && shop._embedded && shop._embedded.owner) {
                        this.resetSource(shop._embedded.owner)
                      }
                    })
                  }
                  this.$emit('shop-change', shop)
                  this.setHasOrderChange(true)
                }
              },
              {
                ref: 'warehouse',
                type: 'multiselect',
                label: this.$t('Warehouse'),
                field: 'warehouse',
                value: this.warehouse,
                disabled: this.checkIsDisabledWarehouse ||
                    this.disabled ||
                    this.warehouse && this.items.length > 0,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { field: 'state', type: 'eq', value: 'active' },
                      { type: 'in', field: 'type', values: ['client', 'virtual'] }
                    ]
                  }

                  if (this.shop?._embedded?.owner) {
                    query.filter.push({ type: 'eq', field: 'owner', value: this.shop._embedded.owner.id })
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: warehouse => {
                  if (!warehouse || !this.warehouse) {
                    this.resetShop(warehouse)
                  } else if (
                      warehouse &&
                      this.shop?._embedded?.owner?.id !== warehouse._embedded.owner.id
                  ) {
                    this.resetShop(warehouse)
                  }

                  this.isDisabledWarehouse = false
                  this.$emit('warehouse-change', warehouse)
                  this.setHasOrderChange(true)
                }
              },
              {
                ref: 'source',
                type: 'multiselect',
                label: this.$t('Source'),
                field: 'source',
                value: this.order && this.order._embedded.source,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                required: true,
                disabled: this.order && !this.order._embedded.shop,
                customLabel: row => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id}) / ${row.handler || this.$t('No handler')} / ${row._embedded?.owner?.name || this.$t('No owner')}`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  if (this.fullShop) {
                    const query = {
                      per_page: 25,
                      page,
                      search,
                      filter: [
                        { type: 'in', field: 'state', values: ['active', 'blocked'] },
                        { type: 'eq', field: 'owner', value: this.fullShop._embedded.owner.id }
                      ]
                    }

                    return this.$service.iSource.getAll(query)
                  } else {
                    const query = {
                      per_page: 25,
                      page,
                      search,
                      filter: [
                        { type: 'in', field: 'state', values: ['active', 'blocked'] },
                        { type: 'eq', field: 'owner', value: this.shop._embedded.owner.id }
                      ]
                    }

                    return this.$service.iSource.getAll(query)
                  }
                },
                onChange: (source) => {
                  this.$emit('update-source', source)
                }
              },
              {
                type: 'select',
                label: this.$t('Payment status'),
                value: this.order && this.order.paymentState,
                disabled: this.disabled,
                field: 'paymentState',
                disabledClean: true,
                customLabel (row) {
                  return row && typeof row === 'object'
                      ? row.name
                      : row
                },
                wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
                options: this.paymentsStates,
                onChange: (paymentState) => {
                  this.$emit('update-order', { paymentState: paymentState.id })
                  this.setHasOrderChange(true)
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.order && this.order.comment,
                max: 255,
                hint: this.$t('Max 255 characters.'),
                label: this.$t('Comment'),
                wrapperStyleClasses: 'col q-pa-xs',
                onInput: comment => {
                  this.$emit('update-order', { comment })
                  this.setHasOrderChange(true)
                }
              }
            ]
          }
        ]
      }
    },
    isAmazon () {
      return this.order?._embedded?.source?.handler === 'amazon'
    },
    checkIsDisabledWarehouse () {
      if (this.order?._embedded?.shop && !this.warehouse) {
        return false
      }

      return this.isDisabledWarehouse
    }
  },
  watch: {
    'order._embedded.warehouse': {
      handler (value, oldValue) {
        if (value !== oldValue) {
          this.integrations()
        }
      },
      deep: true
    },
    orderProducts: {
      handler (value) {
        this.$emit('products-change', value)
      },
      deep: true
    },
    isOrderLoading () {
      if (this.order && this.order.id) {
        const pagination = {
          rowsPerPage: 25,
          page: 1
        }

        this.onRequest({ pagination })
        this.loadData()
      }
    }
  },
  mounted () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))

    if (this.order && this.order.id) {
      const pagination = {
        rowsPerPage: 25,
        page: 1
      }

      this.onRequest({ pagination })
      this.integrations()
    }
  },
  updated () {
    if (this.shop && !this.shop?._embedded?.owner?.id) {
      const query = {
        per_page: 5,
        page: 1,
        filter: [
          { type: 'eq', field: 'id', value: this.shop.id }
        ]
      }
      this.$service.shop.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.fullShop = items[0]
            }
          })
    } else {
      this.fullShop = this.shop
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'setHasOrderChange',
      'setOrder'
    ]),
    integrations (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      this.warehouseOwner = this.order?._embedded?.shop?._embedded.owner._links.self.href.split('/').pop()

      if (!this.warehouseOwner) {
        return {}
      }

      query.filter = [
        {
          type: 'innerjoin',
          field: 'sources',
          alias: 's',
          parentAlias: 'ii'
        },
        {
          type: 'eq',
          field: 'owner',
          value: this.warehouseOwner,
          alias: 's'
        }
      ]

      return this.$service.iIntegration.getAll(query)
          .then(({ items, totalPages, page, totalItems }) => {
            const ifAmazon = items.filter(e => e.adapter === 'amazon')

            return { items, totalPages, page, totalItems }
          })
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilter = false
      this.filters = filter
      return this.onRequest({ pagination: { page: 1 } })
    },
    loadData () {
      if (this.order && !this.order.id) {
        this.loadDefaultValues()
      }
    },
    resetShop (warehouse) {
      if (this.shop?._embedded?.owner?.id === warehouse._embedded.owner.id) {
        return
      }

      this.$emit('shop-change', null)
      this.$refs.formBuilder.$refs.form[0].$refs.shop[0].$refs.shop.reset()

      if (warehouse) {
        this.loadShop(warehouse)
      }
    },
    loadShop (warehouse) {
      const query = {
        page: 1,
        per_page: 5,
        filter: [
          { type: 'in', field: 'state', values: ['active', 'blocked'] },
          { type: 'in', field: 'type', values: ['shop', 'virtual'] }
        ]
      }

      if (warehouse && warehouse._embedded && warehouse._embedded.owner) {
        query.filter.push({ type: 'eq', field: 'owner', value: warehouse._embedded.owner.id })
      }

      return this.$service.shop.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.$emit('shop-change', items[0])
              this.isDisabledShop = true
            }

            return this.order._embedded.shop
          })
    },
    resetWarehouse (shop) {
      this.$emit('warehouse-change', null)
      this.$refs.formBuilder.$refs.form[0].$refs.warehouse[0].$refs.shop.reset()

      if (shop) {
        this.loadWarehouse(shop)
      }
    },
    loadWarehouse (shop) {
      const query = {
        page: 1,
        per_page: 5,
        filter: [
          { type: 'in', field: 'state', values: ['active'] },
          { type: 'in', field: 'type', values: ['client', 'virtual'] }
        ]
      }

      if (shop && shop._embedded && shop._embedded.owner) {
        query.filter.push({ type: 'eq', field: 'owner', value: shop._embedded.owner.id })
      }

      return this.$service.warehouse.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.$emit('warehouse-change', items[0])
              this.isDisabledWarehouse = true
            } else {
              this.isDisabledWarehouse = false
            }

            return Promise.resolve(items)
          })
    },
    resetSource (owner) {
      if (!this.appOptions.orderTypes[this.orderType]?.settings?.loadSingleSource) {
        return
      }

      this.$emit('source-change', null)
      this.$refs.formBuilder.$refs.form[0].$refs.source[0].$refs.source.reset()

      if (owner) {
        this.loadSource(owner)
      }
    },
    loadSource (owner) {
      const query = {
        page: 1,
        per_page: 5,
        filter: [
          { type: 'in', field: 'state', values: ['active'] }
        ]
      }

      query.filter.push({ type: 'eq', field: 'owner', value: owner.id })

      return this.$service.iSource.getAll(query).then(({ items, totalItems }) => {
        if (totalItems === 1) {
          this.$emit('source-change', items[0])
          this.isDisabledShop = false
        }

        return Promise.resolve(items)
      })
    },
    loadDefaultValues () {
      const query = {
        page: 1,
        per_page: 5,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      this.$service.shop.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.$emit('warehouse-change', items[0])
              this.isDisabledWarehouse = true
            }
          })
      query.filter.push({ type: 'eq', field: 'state', value: 'blocked' })
      this.$service.shop.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.$emit('shop-change', items[0])
              this.isDisabledShop = true
            }
          })
    },
    reset () {
      this.orderProducts = []
      this.changes = {}
      this.search = ''

      const pagination = {
        per_page: 25,
        page: 1
      }

      return this.onRequest({ pagination })
    },
    handleReserve (reserve) {
      let data
      if (Array.isArray(reserve) && reserve.length > 0) {
        data = {
          reserve: reserve[0].id
        }
        let reserves = reserve.reduce((acc, el) => {
          acc.push(el.offer)
          return acc
        }, [])
        data.productOffer = reserves
      } else {
        data = {
          reserve: reserve.id,
          productOffer: reserve.offer
        }
      }

      const groups = [
        { field: 'productOffer', alias: 'i' },
        { field: 'warehouse', alias: 'i' },
        { field: 'place', alias: 'i' },
        { field: 'reserve', alias: 'i' }
      ]

      const options = {}
      const query = {
        per_page: 250,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'in', field: 'type', values: ['client', 'virtual'] }
        ]
      }

      query.filter.push({ type: 'eq', field: 'owner', value: this.shop._embedded.owner.id })
      this.$service.warehouse.getAll(query)
          .then(e => {
            const result = e.items.map(el => el.id)
            data.warehouse = result
            this.$refs.itemsInfo.open(data, groups, options)
          })
    },
    create (type) {
      this.$refs.offerModal.open(undefined, { type, shop: this.order._embedded.shop })
    },
    handleSearch (search) {
      this.search = search

      if (this.order && this.order.id) {
        return this.onRequest({ pagination: { per_page: 25, page: 1 } })
      }

      return Promise.resolve(this.items)
    },
    getAll () {
      return this.orderProducts.filter(x => x.count > 0)
    },
    getChanges () {
      if (!this.order || !this.order.id) {
        return this.orderProducts.filter(x => x.count > 0)
      }

      console.log(this.changes)

      return Object.values({
        ...this.changes,
        [this.pagination.page]: this.orderProducts.filter(x => !x.id || x.hasChange)
      }).reduce((acc, arr) => {
        return [...acc, ...arr]
      }, [])
    },
    handleDuplicate (item) {
      const product = {
        count: item.count,
        _embedded: {
          productOffer: item._embedded.productOffer,
          shop: item._embedded?.shop
        }
      }
      let items = [...this.orderProducts, product]
      const products = items.reduce((acc, p) => {
        acc.push(this.convertProduct(p))
        return acc
      }, [])

      this.setHasOrderChange(true)
      this.orderProducts = products
    },
    handleSubmit (data) {
      this.$emit('shop-change', data.shop)
      this.$emit('warehouse-change', data.warehouse)
      const products = data.products.reduce((acc, p) => {
        acc.push(this.convertProduct(p))
        return acc
      }, [])

      this.setHasOrderChange(true)
      this.orderProducts = products
    },
    handleImportByTextSubmit (data) {
      console.log(data.products)

      this.setHasOrderChange(true)
      this.orderProducts = [...this.orderProducts, ...data.products]
    },
    handleImportByText () {
      this.$refs.importProducts.open(this.shop)
    },
    convertProduct (product) {
      if (product.raw) {
        if(product.state === 'deleted') {
          return {
            ...product.raw,
            hasChange: true,
            count: 0
          }
        } else {
          return {
            ...product.raw,
            hasChange: true,
            count: Number(product.count)
          }
        }
      }
      if( product.state === 'deleted') {
        return {
          ...product,
          count: 0,
          price: product._embedded?.productOffer?.price,
          extId: product._embedded?.productOffer?.extId,
          hasChange: true
        }
      } else {
        return {
          ...product,
          count: Number(product.count),
          price: product._embedded?.productOffer?.price,
          extId: product._embedded?.productOffer?.extId,
          hasChange: true
        }
      }
    },
    handleOfferSubmit (offer) {
      let hasProduct = false

      const products = this.orderProducts.map(product => {
        if (product._embedded && product._embedded.productOffer && `${product._embedded.productOffer.id}` === `${offer.id}`) {
          hasProduct = true
          product._embedded.productOffer = offer
        }

        return product
      })

      if (!hasProduct) {
        if (!this.order._embedded.shop) {
          this.$emit('shop-change', offer._embedded.shop)
        }

        products.push(this.convertProduct({ ...offer, count: 1, raw: undefined }))
      }

      this.setHasOrderChange(true)
      this.orderProducts = products
    },
    handleShowInfo (offer) {
      this.$refs.offerModal.open(offer)
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = this.filters

      query.filter.push({ alias: 'op', field: 'order', type: 'eq', value: this.order.id })

      if (this.order._embedded.shop) {
        query.filter.push({ field: 'shop', type: 'eq', value: this.order._embedded.shop.id })
      }

      if (this.search && this.search[this.search.length - 1] !== '*' && this.search[this.search.length - 2] !== ':' && !this.search.includes('%')) {
        query.search = this.search + (this.search[this.search.length - 1] === ':' ? '*' : ':*')
      } else if (this.search.includes('%')) {
        query.search = this.search
      }

      this.isLoading = true
      return this.$service.order.getProducts(query)
          .then(({ items, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            if (query.page > 1) {
              this.changes[query.page - 1] = this.orderProducts.filter(x => !x.id || x.hasChange)
            }
            this.bundleProducts = items.filter(e => e.eav && e.eav['products-order-bundle-order-product'])
            const result = items.filter(e => !e.eav || !e.eav['products-order-bundle-order-product'])

            if (this.changes[query.page]) {

              this.orderProducts = this.mergeChanges(this.changes[query.page], result)
            } else {

              this.orderProducts = result
            }

            return this.getOffersByProducts(items)
          })
          .then(({ items }) => {
            // Clone products and set right offer in embedded
            const products = this.orderProducts.map(item => {
              const offer = items.find(({ id }) => `${id}` === `${item._embedded.productOffer.id}`)
              const i = _.cloneDeep(item)

              if (offer) {
                i._embedded.productOffer = _.cloneDeep(offer)
              } else {
                this.addErrorNotification(`Product ${i._embedded.productOffer.id} from store ${i._embedded.shop.id} is with status ${i._embedded.productOffer.state}`)
              }

              return i
            })

            this.orderProducts = products

            return items
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    setProducts (products) {
      this.orderProducts = _.cloneDeep(products)

      this.pagination = {
        ...this.pagination,
        page: 1,
        rowsNumber: products.length
      }
    },
    mergeChanges (changes, items) {
      const updatedItems = [...items]

      changes.forEach(x => {
        if (x.id) {
          const index = updatedItems.findIndex(i => i.id === x.id)

          if (index > -1) {
            updatedItems[index] = x
          } else {
            updatedItems.push(x)
          }
        } else {
          updatedItems.push(x)
        }
      })

      return updatedItems
    },
    getOffersByProducts (items) {
      if (items.length === 0) {
        return Promise.resolve([])
      }

      // Get offers from products
      const values = items.reduce((acc, item) => {
        if (!acc.includes(item._embedded.productOffer.id)) {
          acc.push(item._embedded.productOffer.id)
        }

        return acc
      }, [])

      const query = {
        per_page: values.length,
        page: 1,
        filter: [
          { type: 'in', field: 'id', values }
        ]
      }

      if (this.order._embedded.shop) {
        query.filter.push({ field: 'shop', type: 'eq', value: this.order._embedded.shop.id })
      }

      return this.$service.offer.getAll(query)
    },
    getReserve (item) {

      const groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x)
          return rv
        }, {})
      }
      const grouped = groupBy(this.reserves, 'offer')
      const result = Object.values(grouped)

      return result.filter(reserve => reserve).find(reserve => `${reserve[0].offer}` === `${item._embedded.productOffer.id}`)
    },
    handleNumberUpdate ({ product, update }) {
      Object.entries(update).forEach(([key, value]) => {
        product[key] = value
      })

      product.hasChange = true

      this.setHasOrderChange(true)
    },
    deleteRow (offerId) {
      const products = this.orderProducts.map(val => {
        if (val._embedded.productOffer.id === offerId) {
          return { ...val, count: 0, hasChange: true }
        }

        return val
      })

      this.setHasOrderChange(true)
      this.orderProducts = products
    },
    handleSaveSKU (items) {
      let result = items.map(e => e.shop = this.shop)
      console.log(result)
    }
  }
}
</script>
